import { useRouter } from 'next/router';
import type { ReactNode, RefObject } from 'react';
import React, {
  useCallback,
  createRef,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import TagManager from 'react-gtm-module';

import { debounce, omitUrlQueryParams } from './JavascriptUtils';

export const mergeClassNames = (
  classNames: (string | null | undefined | false)[],
) => {
  return classNames.filter(Boolean).join(' ');
};

export const renderHTML = (rawHTML: string | undefined) =>
  React.createElement('span', { dangerouslySetInnerHTML: { __html: rawHTML } });

// Get the value from the previous render
export const usePrevious = <ValueType>(value: ValueType) => {
  const ref = useRef<ValueType | undefined>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

// Get the last truthy value from previous renders
export const useTruthyPrevious = <ValueType>(value: ValueType) => {
  const ref = useRef<ValueType | undefined>();

  useEffect(() => {
    if (value) {
      ref.current = value;
    }
  });

  return ref.current;
};

// Return null during the first render then the right value
// (useful for content that depends on client-side variables)
export const useNullFirstRender = (children: ReactNode) => {
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  return firstRender ? null : children;
};

export const useBodyClass = (className: string) => {
  useEffect(() => {
    document.body.classList.add(className);

    return () => {
      document.body.classList.remove(className);
    };
  }, [className]);
};

export const useClickOutside = (
  wrapperRef: RefObject<HTMLElement>,
  callback: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef?.current?.contains(event.target as HTMLElement)
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, wrapperRef]);
};

type Position = 'before' | 'after';

export default function useVisibilityOutOfViewPort<Element extends HTMLElement>(
  offsetOfElementTop = 0,
): [boolean, React.RefObject<Element>, Position] {
  const [isVisible, setIsVisible] = useState(true);
  const [elementPositionViewPort, setElementPositionViewPort] =
    useState<Position>('before');
  const currentElement = createRef<Element>();
  const throttleMilliseconds = 100;

  const onScroll = debounce(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const elementTopPositionOnViewPort =
      currentElement.current.getBoundingClientRect().top;
    const isElementStillOnViewPort =
      elementTopPositionOnViewPort + offsetOfElementTop >= 0;
    setIsVisible(isElementStillOnViewPort);
    setElementPositionViewPort(
      elementTopPositionOnViewPort > 0 ? 'before' : 'after',
    );
  }, throttleMilliseconds);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  return [isVisible, currentElement, elementPositionViewPort];
}

export const usePageType = () => {
  const mapPathToNameObj: Record<string, string> = {
    '/': 'homepage',
    '/garage/[id]': 'home garage',
    '/promotion': 'landingPage',
    '/promotion/[slug]': 'landingPage',
    '/garage-auto': 'garage locator home',
    '/garage-auto/region/[slug]': 'garage locator region',
    '/garage-auto/departement/[slug]': 'garage locator departement',
    '/garage-auto/ville/[slug]': 'garage locator ville',
    '/garage-auto/recherche-garage': 'garage locator recherche',
    '/guides/guide-conseil': 'guide conseil',
    '/guides/guide-conseil/[familyId]': 'categorie guide conseil',
    '/guides/guide-conseil/[familyId]/[tipId]': 'page conseil',
    '/espace-professionnel/rendez-vous-en-ligne': 'pro',
    '/garage/[id]/prestation': 'prestations',
    '/garage/[id]/prestation/[slug]': 'prestations',
    '/prestation': 'prestations',
    '/prestation/[slug]': 'prestations',
  };

  const { pathname } = useRouter();

  const pageName = mapPathToNameObj[pathname] || 'autres';

  return pageName;
};

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted.current;
};

type DataTestIdType = {
  dataTestId?: string;
  prefix?: string;
};
export const getDataTestId = ({ dataTestId, prefix }: DataTestIdType) => {
  if (!dataTestId) {
    return {};
  }
  return {
    'data-testid': [prefix, dataTestId].filter((el) => el?.length).join('_'),
  };
};

export const generateDataTestId = (element: string) =>
  element
    .replace(/[^a-z0-9A-Z -]/g, '')
    .replace(/\s+/g, '_')
    .replace(/-+/g, '_')
    .toLowerCase();

export const useScrollToView = (isSmooth = false) => {
  const scrollPointRef = useRef<HTMLDivElement>(null);

  const handleOnClick = () => {
    scrollPointRef?.current?.scrollIntoView(
      isSmooth
        ? {
            behavior: 'smooth',
          }
        : true,
    );
  };

  return { scrollPointRef, handleOnClick };
};

export const usePageUrl = () => {
  const router = useRouter();

  const getPageUrl = (pageNumber = 1) => {
    const url = omitUrlQueryParams(router.asPath);
    if (pageNumber === 1) return url;

    return `${url}?page=${pageNumber}`;
  };

  return {
    getPageUrl,
    page:
      typeof router.query.page === 'string'
        ? parseInt(router.query.page, 10)
        : 1,
  };
};

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key (/!\ localStorage is not always available)
      const item = window.localStorage?.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));

          // Dispatch the custom event to notify other components
          const event = new Event('localStorageUpdate');
          window.dispatchEvent(event);
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key, storedValue],
  );

  useEffect(() => {
    const syncState = () => {
      const item = window.localStorage.getItem(key);
      if (item !== null) {
        setStoredValue(JSON.parse(item));
      }
    };

    // Listen for the custom event and sync state
    window.addEventListener('localStorageUpdate', syncState);

    return () => {
      window.removeEventListener('localStorageUpdate', syncState);
    };
  }, [key]);

  const clear = useCallback(() => {
    try {
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key);

        // Dispatch the custom event to notify other components
        const event = new Event('localStorageUpdate');
        window.dispatchEvent(event);
      }
    } catch (error) {
      console.log(error);
    }
  }, [key]);

  return [storedValue, setValue, clear] as const;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useToggle = (initialState = false): [boolean, any] => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback(
    (): void => setState((prevState) => !prevState),
    [],
  );
  return [state, toggle];
};

// Finds whether a component's `children` prop includes a React element of the
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasChildOfType<P = any>(
  children: React.ReactNode,
  type: string | React.JSXElementConstructor<P>,
): boolean {
  return React.Children.toArray(children).some(
    (child) => React.isValidElement(child) && child.type === type,
  );
}

export const usePageViewTracker = () => {
  const mapPathToNameObj: Record<string, string> = useMemo(
    () => ({
      '/annonces-vehicules': 'annonces vehicules',
      '/promotion': 'promotion',
      '/espace-professionnel': 'espace professionnel',
      '/garage-auto': 'garage auto',
      '/proposition': 'proposition',
      '/commande': 'commande',
    }),
    [],
  );

  const { pathname } = useRouter();

  const matchedPath = Object.keys(mapPathToNameObj).find((path) =>
    pathname.startsWith(path),
  );
  const pageName = matchedPath ? mapPathToNameObj[matchedPath] : 'autres';

  useEffect(() => {
    const isTrackedPage = Object.keys(mapPathToNameObj).some((page) =>
      pathname.startsWith(page),
    );
    if (isTrackedPage) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'virtualPageview',
          virtualPagePath: window.location.pathname,
          virtualPageTitle: pageName,
        },
      });
    }
  }, [pathname, mapPathToNameObj, pageName]);

  return pageName;
};
