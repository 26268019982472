import type { NewsMessage } from 'src/models';

import type { UseQueryConfig } from 'src/utils/client/ClientAPIUtils';
import { usePatchedSWR } from 'src/utils/client/ClientAPIUtils';

export type NewsMessageQueryData = NewsMessage;
export type NewsMessageQueryVariables = undefined;

export const useNewsMessageQuery = (
  config?: UseQueryConfig<NewsMessageQueryData, NewsMessageQueryVariables>,
) =>
  usePatchedSWR<NewsMessageQueryData>(
    {
      url: 'news-message',
    },
    { refreshInterval: 120000, ...config },
  );
