import type { FunctionComponent, ReactNode } from 'react';
import React, { useContext, useMemo, useReducer } from 'react';
import type { Garage } from 'src/models';

export type QuotationEngineStateGarage = Pick<
  Garage,
  | 'id'
  | 'garIdNetwork'
  | 'name'
  | 'typeEnseigne'
  | 'zipCode'
  | 'location'
  | 'city'
>;

type State = {
  show: boolean;
  initialCategoryId: number | undefined;
  initialSubcategoryId: number | undefined;
  initialServiceId: string | undefined;
  initialVehicleId: string | undefined;
  initialLatitude: number | undefined;
  initialLongitude: number | undefined;
  garage?: QuotationEngineStateGarage;
  landingPageId: number | undefined;
  fromCart: boolean | undefined;
  couponCode: string | undefined;
};

const initialState: State = {
  show: false,
  initialCategoryId: undefined,
  initialSubcategoryId: undefined,
  initialServiceId: undefined,
  initialVehicleId: undefined,
  initialLatitude: undefined,
  initialLongitude: undefined,
  garage: undefined,
  landingPageId: undefined,
  fromCart: undefined,
  couponCode: undefined,
};

type Action =
  | {
      type: 'open';
      categoryId?: number;
      subcategoryId?: number;
      serviceId?: string;
      vehicleId?: string;
      latitude?: number;
      longitude?: number;
      garage?: QuotationEngineStateGarage;
      landingPageId?: number;
      fromCart?: boolean;
      couponCode?: string;
    }
  | { type: 'close' };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'open':
      return !state.show
        ? {
            ...state,
            show: true,
            initialCategoryId: action.categoryId,
            initialSubcategoryId: action.subcategoryId,
            initialServiceId: action.serviceId,
            initialVehicleId: action.vehicleId,
            initialLatitude: action.latitude,
            initialLongitude: action.longitude,
            garage: action.garage,
            landingPageId: action.landingPageId,
            fromCart: action.fromCart,
            couponCode: action.couponCode,
          }
        : state;
    case 'close':
      return initialState;
    default:
      throw new Error();
  }
};
type Dispatch = (action: Action) => void;

type OpenQuotationEngineParams = {
  categoryId?: number;
  subcategoryId?: number;
  serviceId?: string;
  vehicleId?: string;
  landingPageId?: number;
  fromCart?: boolean;
  couponCode?: string;
  garage?: QuotationEngineStateGarage;
} & (
  | {
      latitude: number;
      longitude: number;
    }
  | {
      latitude?: never;
      longitude?: never;
    }
);

const QuotationEngineContext = React.createContext<{
  state: State;
  dispatch: Dispatch;
  openQuotationEngine: (args: OpenQuotationEngineParams) => void;
  garageSlug: string | null;
  closeQuotationEngine: () => void;
}>({} as never);

export const useQuotationEngineContext = () =>
  useContext(QuotationEngineContext);

type QuotationEngineContextProviderType = {
  garageSlug: string | null;
  children: ReactNode;
};

export const QuotationEngineContextProvider: FunctionComponent<
  QuotationEngineContextProviderType
> = ({ garageSlug, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openQuotationEngine = (args: OpenQuotationEngineParams) => {
    dispatch({ type: 'open', ...args });
  };

  const closeQuotationEngine = () => {
    dispatch({ type: 'close' });
  };
  const value = useMemo(() => {
    return {
      state,
      dispatch,
      openQuotationEngine,
      closeQuotationEngine,
      garageSlug,
    };
  }, [state, garageSlug]);
  return (
    <QuotationEngineContext.Provider value={value}>
      {useMemo(() => children, [children])}
    </QuotationEngineContext.Provider>
  );
};
